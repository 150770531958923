<template>
  <div>
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <v-layout style="justify-content: end" class="d-flex mb-3">
        <div>
          <v-btn color="primary" @click="onGotoCreatePartnerPrice">
            <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
            {{ $t("price_list") }}
          </v-btn>
        </div>
      </v-layout>
      <v-data-table
        class="border-primary"
        hide-default-footer
        height="80vh"
        fixed-header
        :no-data-text="$t('not_found')"
        :headers="header"
        :header-props="{ sortIcon: 'mdi-menu-swap' }"
        :items="items"
        :items-per-page="items.length"
        @click:row="onShowUpdatePartnerPrice"
      >
        <template v-slot:[`item.name`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ item.name }}
          </td>
        </template>
        <template v-slot:[`item.partnerSaleAccount`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ item.partnerSaleAccount }}
          </td>
        </template>
        <template v-slot:[`item.shippingType`]="{ item }">
          {{ getTextTransportType(item.shippingType) }}
        </template>
        <template v-slot:[`item.serviceName`]="{ item }">
          {{ getTextService(item.serviceName) }}
        </template>
        <template v-slot:[`item.updatedByName`]="{ item }">
          {{ item.updatedByName }}
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          {{ formatDate(item.updatedAt) }}
        </template>
      </v-data-table>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading :is-show="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />
  </div>
</template>

<script>
import Breadscrumbs from "@/components/base/Breadscrumbs";
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import constants from "@/constants";
import { DateTimeUtils } from "@/helpers/dateTimeUtils.js";
import { StringUtils } from "@/helpers/stringUtils";
import routePaths from "@/router/routePaths";
import { mapActions } from "vuex";
import { PricesService } from "@/services/pricesService";
import { DataBreadCrumbUtils } from "@/helpers/dataBreadCrumbUtils";

export default {
  components: {
    DialogLoading,
    DialogNotification,
    Breadscrumbs,
  },
  data() {
    return {
      partnerId: parseInt(this.$route.query.id),
      partnerName: this.$route.query.name,
      // notifications
      showNoti: false,
      typeIcon: constants.typeAlert.warning,
      message: "",
      showLoading: false,
      items: [],
      breadcrumbs: [
        {
          text: this.$t("partner_price_list"),
          isActive: false,
          href: `${routePaths.PARTNER_LIST}`,
        },
        {
          text: "",
          disabled: true,
          isActive: true,
        },
      ],
      timeoutOnScroll: null,
      persistent: false,
      typeNoti: constants.typeAlert.warning,
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
      currentPoId: JSON.parse(localStorage.getItem("CURRENT_USER")).poId,
    };
  },
  computed: {
    header() {
      if (this.partnerId === constants.partnerGroup.ninjaVan) {
        return [
          {
            text: this.$t("name_prices"),
            value: "name",
            class: "text-subtitle-2",
          },
          {
            text: this.$t("account_link"),
            value: "partnerSaleAccount",
            class: "text-subtitle-2",
            sortable: false,
          },
          {
            text: this.$t("shipping_method"),
            value: "shippingType",
            class: "text-subtitle-2",
            sortable: false,
          },
          {
            text: this.$t("ship_service"),
            value: "serviceName",
            class: "text-subtitle-2",
            sortable: false,
          },
          {
            text: this.$t("updated_by"),
            value: "updatedByName",
            class: "text-subtitle-2",
          },
          {
            text: this.$t("updated_date"),
            value: "updatedAt",
            class: "text-subtitle-2",
          },
        ];
      }
      return [
        {
          text: this.$t("name_prices"),
          value: "name",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("account_link"),
          value: "partnerSaleAccount",
          class: "text-subtitle-2",
          sortable: false,
        },
        {
          text: this.$t("ship_service"),
          value: "serviceName",
          class: "text-subtitle-2",
          sortable: false,
        },
        {
          text: this.$t("updated_by"),
          value: "updatedByName",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("updated_date"),
          value: "updatedAt",
          class: "text-subtitle-2",
        },
      ];
    },
    formatDateTimeHm(datetime) {
      return DateTimeUtils.formatDateTime(datetime, constants.formatDateTimeHm);
    },
    getIcon() {
      return StringUtils.getIconSrc(this.typeIcon);
    },
  },
  created() {
    if (
      (window.location.pathname &&
        !this.currentRole.includes("ROLE_ADMIN") &&
        !this.currentRole.includes("ROLE_ROOT")) ||
      this.currentPoId !== constants.postOffice.primary
    ) {
      this.toggleDialogNoti({
        state: true,
        msg: this.$t("no-mission-msg"),
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, constants.timeOutRole);
    }
    this.initData();
  },
  methods: {
    ...mapActions("layout", ["setBreadcrumbs"]),
    toggleDialogNoti(
      pars = {
        state: false,
        persistent: false,
        type: constants.typeAlert.warning,
        msg: "",
      }
    ) {
      this.showNoti = pars.state;
      this.persistent = pars.persistent;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
    onGotoCreatePartnerPrice() {
      this.$router.push({
        path: routePaths.CREATE_PARTNER_PRICE,
        query: { id: this.$route.query.id, name: this.partnerName },
      });
      this.addBreadCrumbs(routePaths.CREATE_PARTNER_PRICE);
    },
    onShowUpdatePartnerPrice(item) {
      this.$router.push({
        path: routePaths.DETAIL_PARTNER_PRICE,
        query: {
          detailId: item.id,
          id: this.$route.query.id,
          name: this.partnerName,
        },
      });
    },
    addBreadCrumbs(pathActive) {
      const breadCrumbs =
        DataBreadCrumbUtils.getBreadCrumbByPathActive(pathActive);
      this.setBreadcrumbs(breadCrumbs);
    },
    async initData() {
      if (this.partnerId) {
        await this.getAdminPriceGroup();
      }
      this.breadcrumbs[1].text = this.partnerName;
    },
    async getAdminPriceGroup() {
      try {
        const data = await PricesService.getAdminPricesGroup(this.partnerId);
        this.items = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    getTextTransportType(val) {
      return val === constants.transportType.ship
        ? this.$t("shipper_pickup")
        : this.$t("customer_bring_order");
    },
    getTextService(val) {
      if (val === "NORMAL") {
        return this.$t("normal");
      } else if (val === "EXPRESS") {
        return this.$t("fast");
      } else if (val === "NORMAL") {
        return this.$t("normal");
      } else {
        return this.$t("over");
      }
    },
    formatDate(val) {
      return DateTimeUtils.formatDateTime(val, constants.formatDateTimeHm);
    },
  },
};
</script>
<style src="@/styles/postOffice.scss" lang="scss">
</style>
